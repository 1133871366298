<template>
    <v-app>
        <v-main>
            <div class="app pr_app login">
                <h1 class="text-h4">同步 & 配置</h1>
                <div class="more">
                    <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            天气同步
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="panel-content">
                            <v-col
                                class="d-flex select-v"
                                cols="12"
                                sm="6"
                            >
                                <!-- <v-select
                                    :items="darkmodeItems"
                                    :label="weatherCity"
                                    v-model="darkmodeValue"
                                    @change="changeDarkMode"
                                    outlined
                                ></v-select> -->
                                <v-text-field
                                label="天气同步城市（如：广州）"
                                v-model="weatherCity"
                                outlined
                                type="text"
                            ></v-text-field>
                            </v-col>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                    <v-btn id="clk_btn" depressed 
                        :loading="clk_load" :disabled="clk_load" style="margin-top: 30px;">保存</v-btn>
                </div>
            </div>
            <v-alert
            class="alert"
                border="left"
                elevation="3"
                :type="alertType"
                v-text="alertText"
                v-show="alertShow"
            ></v-alert>
        </v-main>
    </v-app>
</template>

<script>
// import i18n from '../i18n';
// import md5 from 'js-md5';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
var Lang = {
    title: "添加设备",
    username: "用户名 (3-15)",
    verifyCode: "设备 ID",
    verifyCodeInput: "",
    bkendUrl: "https://p1.endpoint.lazybones.club",
    mode: "继续",
    promptText: `
        在此处添加您的 PhoenixDesk 设备。<br><br>
        点击下方「继续」，输入设备 UUID 即可完成绑定。
    `,
    deviceName: "PhoenixDesk 设备",
    deviceNameLabel: "设备名称",
    weatherCity: '',
    clk_load: false,
};
Lang.alertText = Lang.alertType= Lang.l_password = Lang.l_username = Lang.l_email  = null;
Lang.alertShow = false;
Lang.clk_load = false;
Lang.recaptcha = "";
export default {
    name: "CKY",

    components: {},

    data: () => (Lang),

    methods: {
        
    },
    created: async function() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');
        // console.log(token);
        this.recaptcha = token;
        console.log(this.$route.query.id);
    }
};

</script>

<style>
    .pr_app.login {
        text-align: center;
        margin-top: 11vh!important;
        transition: all .3s;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
    .select-v {
        margin: 0 auto;
        padding: 0;
    }
    .alert {
        position: fixed !important;
        top: 1rem;
        right: 1rem;
        /* min-width: 6rem; */
    }
</style>


<template>
    <v-app>
        <v-main>
            <div class="app about">
                <h1 class="text-h3" v-text="title"></h1>
                <div class="more text-h6 font-weight-light" v-html="more"></div>
                <!-- <v-btn @click="dash" depressed v-text="index_btn">
                </v-btn> -->
            </div>
        </v-main>
    </v-app>
</template>

<script>
import i18n from '../i18n';
var Lang = {
    title: "关于 PhoenixDesk",
    more: `PhoenixDesk，下一代智能桌面摆件，支持全平台绑定同步。<br><br>
    与以往的桌面摆件不同，它具有智能显示屏，可以显示如天气、时间、日历等实用信息。同时具备闹钟功能，可自定义铃声。<br><br>
    此页面 (pd.lazybones.club) 是 PhoenixDesk 的官方 Web 应用程序，它可以方便的使用蓝牙 或 Classic 方式进行同步。目前仅针对
    <b>中国大陆、中国香港特别行政区、中国澳门特别行政区、中国台湾地区</b> 提供服务，其他地区用户不保证服务的可达性 (SLA)。如果您正在使用代理网络，我们建议您关闭。<br><br>
    我们尊重您的隐私，您提交的所有信息都会被我们以妥善的方式保存，您的密码经过<b>不可逆</b>加密，任何人都无法获取。<br>
    对于电子邮件地址的使用，仅作为验证账号使用，所有电子邮件地址都会被妥善保存。我们可能会向您的电子邮件地址推送一些重要的服务通知等，如果您不想接收，您可以随时退订。<br>
    Service Worker 技术是一项重要的 Web 技术，启用 Service Worker 可以帮助用户更快的加载网页，获取状态的实时通知等。<br><br>
    PhoenixDesk 由 GZFLS Lazybones 社团开发，官网为 <i>pd.lazybones.club</i>, 验证码发送邮件地址为 <i>phoenixdesk@163.com</i>.<br><br>
    如果您在使用中遇到任何问题，或者有任何建议，欢迎您随时在 <a href="https://support.qq.com/product/501644/">腾讯兔小巢</a> 联系我们。<br><br>
    <b>&copy; 2023 Made with Love ❤ by GZFLS Lazybones</b>`,
    login: "立即登录",
    todash: "管理面板",
    index_btn: "现在开始"
}
document.title = i18n("auto", "title")["index"];
export default {
    name: "ESHexoN",

    components: {},

    data: () => (Lang),

    methods: {
        dash() {
            if (!localStorage.getItem("login_token") && !sessionStorage.getItem("login_token")) {
                this.$router.push("/login/");
            } else {
                this.$router.push("/dash/");
            }
        }
    }
};
</script>

<style>
    .app.about {
        text-align: center;
        margin-top: 8vh;
        transition: all .3s;
        margin-bottom: 8vh;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
        overflow: auto;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
</style>


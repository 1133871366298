<template>
    <v-app>
        <v-main>
            <div class="app pr_app login">
                <h1 class="text-h4">验证账号</h1>
                <div class="more">
                
                    <div class="form">
                        <v-col class="select-v"
                            cols="12"
                            v-show="mode != '发送验证码'"
                            >
                            <v-text-field
                                :label="verifyCode"
                                v-model="verifyCodeInput"
                                outlined
                                maxlength="7"
                                @input="toU"
                            ></v-text-field>
                        </v-col>

                        <div v-show="mode == '发送验证码'">
                            <p style="opacity: 0.8;">在开始使用您的帐号前，请先完成账号验证。<br><br>点击下方「发送验证码」后，我们将向您的邮箱地址发送一封带有验证码的电子邮件。</p>
                            <br>
                        </div>
                        
                        <v-btn id="clk_btn" @click="login" depressed 
                        :loading="clk_load" :disabled="clk_load" v-text="mode">
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-alert
            class="alert"
                border="left"
                elevation="3"
                :type="alertType"
                v-text="alertText"
                v-show="alertShow"
            ></v-alert>
        </v-main>
    </v-app>
</template>

<script>
// import i18n from '../i18n';
// import md5 from 'js-md5';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
var Lang = {
    title: "验证账号",
    username: "用户名 (3-15)",
    verifyCode: "验证码",
    verifyCodeInput: "",
    bkendUrl: "https://p1.endpoint.lazybones.club",
    mode: "发送验证码"
};
Lang.alertText = Lang.alertType= Lang.l_password = Lang.l_username = Lang.l_email  = null;
Lang.alertShow = false;
Lang.clk_load = false;
Lang.recaptcha = "";
export default {
    name: "CKY",

    components: {},

    data: () => (Lang),

    methods: {
        async login() {
            let username = localStorage.getItem("username");
            let loginToken = localStorage.getItem("login_token");
            if (!username || !loginToken) {
                this.alertType = "error",
                this.alertText = "账号未登录";
                this.alertShow = true;
                return false;
            }
            if (!this.recaptcha) {
                this.alertType = "error",
                this.alertText = "Google reCAPTCHA 加载失败";
                this.alertShow = true;
                return false;
            }
            this.clk_load = true;
            if (this.mode == "发送验证码") {
                let f_d = await fetch(this.bkendUrl + "/usr/verify", {
                    method: "POST",
                    body: JSON.stringify({
                        username,
                        token: loginToken,
                        recaptcha: this.recaptcha,
                    }),
                }).then(res => res.text());
                if (f_d.startsWith("200")) {
                    this.clk_load = false;
                    this.alertType = "success",
                    this.alertText = "发送成功，验证码 " + f_d.split(",")[1] + " 分钟内有效";
                    this.alertShow = true;
                    this.mode = "验证";
                } else {
                    if (f_d.startsWith("400")) {
                        this.clk_load = false;
                        this.alertType = "error",
                        this.alertText = "发送失败，速度过快";
                        this.alertShow = true;
                        this.mode = "验证";
                    } else if (f_d.startsWith("403")) {
                        this.clk_load = false;
                        this.alertType = "error",
                        this.alertText = "您无权请求此接口";
                        this.alertShow = true;
                        setTimeout(() => {this.$router.push("/");}, 2000);
                    }
                }
            } else {
                let f_d = await fetch(this.bkendUrl + "/usr/verify", {
                    method: "PUT",
                    body: JSON.stringify({
                        username,
                        token: loginToken,
                        verifyCode: this.verifyCodeInput
                    }),
                }).then(res => res.text());
                if (f_d.startsWith("200")) {
                    this.clk_load = false;
                    this.alertType = "success",
                    this.alertText = "验证成功"
                    this.alertShow = true;
                    setTimeout(() => {this.$router.push("/");}, 2000);
                } else {
                    let wd = "";
                    if (f_d.startsWith("403") && f_d.split(",")[1] == "ALREADY") wd = ", 账号状态异常";
                    if (f_d.startsWith("403") && f_d.split(",")[1] == "ERROR_CODE") wd = ", 验证码错误";
                    this.clk_load = false;
                    this.alertType = "error",
                    this.alertText = "验证失败" + wd;
                    this.alertShow = true;
                    setTimeout(()=>{window.location.reload();}, 2000);
                }
            }
        },
        toU() {
            this.verifyCodeInput = this.verifyCodeInput.toUpperCase(); 
        }
    },
    created: async function() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');
        // console.log(token);
        this.recaptcha = token;
    }
};

</script>

<style>
    .pr_app.login {
        text-align: center;
        margin-top: 11vh!important;
        transition: all .3s;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
    .select-v {
        margin: 0 auto;
        padding: 0;
    }
    .alert {
        position: fixed !important;
        top: 1rem;
        right: 1rem;
        /* min-width: 6rem; */
    }
</style>


<template>
    <v-app>
        <v-main>
            <div class="app dash">
                <h1 class="text-h3">设备</h1>
                <div class="more text-h6 font-weight-light cardgroup">
                    <v-btn depressed class="mgb" @click="addFunction">添加设备</v-btn>
                    <v-progress-linear
                        indeterminate
                        v-show="!list && !empty"
                        class="mgb"
                    ></v-progress-linear>
                    <p v-show="empty">暂无设备</p>
                    <div class="card cyan" v-for="id in list" :key="id.info.name">
                        <div class="txtgroup" @click="jmp(id.id);">
                            <p><span v-text="id.info.name || '未知设备'"></span>&nbsp;&nbsp;&nbsp;<span style="opacity: 0.6;" v-text="id.info.type || '未知'"></span></p>
                            <p><span class="op8h">PhoenixDesk 设备。</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import i18n from '../i18n';
var Lang = {
    list: null,
    info: {},
    empty: false,
};
Lang.posts_num = Lang.drafts_num = "...";
Lang.bkendUrl = "https://p1.endpoint.lazybones.club";
document.title = i18n("auto", "title")["dash"];
export default {
    name: "ESHexoN",

    components: {},

    data: () => (Lang),

    methods: {
        dash() {
            if (!localStorage.getItem("login_token") && !sessionStorage.getItem("login_token")) {
                this.$router.push("/login/");
            } else {
                this.$router.push("/dash/");
            }
        },
        addFunction() {
            this.$router.push("/dash/add/");
        },
        jmp(id) {
            console.log(id)
            this.$router.push({
                path: '/dash/sync/',
                query: {id,}
            });
        }
    },

    created: async function() {
        // 确保 Token 有效
        if (!localStorage.getItem("login_token") || !localStorage.getItem("username")) {
            this.$router.push("/login/");
        }
        let check_token = await fetch(this.bkendUrl+"/usr/token?username="+localStorage.getItem("username")+"&token="+localStorage.getItem("login_token"), {
            method: "GET",
        }).then(res => res.text());
        if (check_token != '200') {
            localStorage.removeItem("login_token");
            localStorage.removeItem("username");
            this.$router.push("/login/");
        }
        // 获取设备列表
        let device_list = await fetch(this.bkendUrl+"/device/list?username="+localStorage.getItem("username")+"&token="+localStorage.getItem("login_token"), {
            method: "GET",
        }).then(res => res.text());
        this.list = JSON.parse(device_list);
        if (device_list == "{}") this.empty = true;
    }
};
</script>

<style>
    .mgb {
        margin-bottom: 30px;
    }
    .app.dash  {
        text-align: center;
        margin-top: 11vh;
        transition: all .3s;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
        overflow-y: scroll;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
    .card {
        width: 100%;
        background-color: #ffffff;
        height: 10rem;
        /* display: flex; */
        /* justify-content: center;
        align-items: center; */
        border-radius: 6px;
        cursor: pointer;
        user-select: none;
        text-align: left;
        padding: 30px;
        margin-bottom: 20px;
    }
    .txtgroup {
        padding-bottom: 5px;
        font-weight: 600;
        font-size: 1.5rem;
    }
    span.op8h {
        opacity: 0.8;
        font-weight: 400;
        font-size: 1.3rem;
    }
    .card.mgr {
        margin-right: 1%;
    }
    .cardgroup {
        /* display: flex; */
        height: auto;
        max-height: 60vh;
        justify-content: center;
        align-items: center;
        transition: all .2s;
    }
    .dark_theme .cardgroup {
        opacity: 0.8;
    }
    .org {
        background-color: #fd960e;
    }
    .gre {
        background-color: #67d46c;
    }
    .blu {
        background-color: #13d9f1;
    }
    .gry {
        background-color: #999999;
    }
</style>


<template>
    <v-app>
        <v-main>
            <div class="app pr_app login">
                <h1 class="text-h4">添加设备</h1>
                <div class="more">
                
                    <div class="form">
                        <v-col class="select-v"
                            cols="12"
                            v-show="mode != '继续'"
                            >
                            <v-text-field
                                :label="verifyCode"
                                v-model="verifyCodeInput"
                                outlined
                                maxlength="36"
                                @input="toU"
                            ></v-text-field>
                            <v-text-field
                                :label="deviceNameLabel"
                                v-model="deviceName"
                                outlined
                                maxlength="20"
                            ></v-text-field>
                        </v-col>

                        <div v-show="mode == '继续'">
                            <p style="opacity: 0.8;" v-html="promptText"></p>
                            <br>
                        </div>
                        
                        <v-btn id="clk_btn" @click="login" depressed 
                        :loading="clk_load" :disabled="clk_load" v-text="mode">
                        </v-btn>
                    </div>
                </div>
            </div>
            <v-alert
            class="alert"
                border="left"
                elevation="3"
                :type="alertType"
                v-text="alertText"
                v-show="alertShow"
            ></v-alert>
        </v-main>
    </v-app>
</template>

<script>
// import i18n from '../i18n';
// import md5 from 'js-md5';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
var Lang = {
    title: "添加设备",
    username: "用户名 (3-15)",
    verifyCode: "设备 ID",
    verifyCodeInput: "",
    bkendUrl: "https://p1.endpoint.lazybones.club",
    mode: "继续",
    promptText: `
        在此处添加您的 PhoenixDesk 设备。<br><br>
        点击下方「继续」，输入设备 UUID 即可完成绑定。
    `,
    deviceName: "PhoenixDesk 设备",
    deviceNameLabel: "设备名称",
};
Lang.alertText = Lang.alertType= Lang.l_password = Lang.l_username = Lang.l_email  = null;
Lang.alertShow = false;
Lang.clk_load = false;
Lang.recaptcha = "";
export default {
    name: "CKY",

    components: {},

    data: () => (Lang),

    methods: {
        async login() {
            if (this.deviceName.length > 20) return false;
            let username = localStorage.getItem("username");
            let loginToken = localStorage.getItem("login_token");
            if (!username || !loginToken) {
                this.alertType = "error",
                this.alertText = "账号未登录";
                this.alertShow = true;
                return false;
            }
            this.clk_load = true;
            if (this.mode == "继续") {
                Lang.clk_load = false;
                this.mode = "添加设备";
            } else {
                if (!this.recaptcha) {
                    this.alertType = "error",
                    this.alertText = "Google reCAPTCHA 加载失败";
                    this.alertShow = true;
                    setTimeout(() => {window.location.reload();}, 2500);
                    return false;
                }
                if (this.verifyCodeInput.length != 36 || this.verifyCodeInput.split("-").length != 5) {
                    this.alertType = "error",
                    this.alertText = "错误的 ID 格式";
                    this.alertShow = true;
                    this.clk_load = false;
                    setTimeout(() => {this.alertShow = false;}, 2500);
                    return false;
                }
                let rs = await fetch(this.bkendUrl + '/device/add', {
                    method: "POST",
                    body: JSON.stringify({
                        recaptcha: this.recaptcha,
                        username,
                        token: loginToken,
                        deviceID: this.verifyCodeInput,
                        deviceInfo: {
                            type: "UNKNOWN",
                            name: this.deviceName || "PhoenixDesk 设备",
                        }
                    })
                }).then(res => res.text());

                if (rs == '200') {
                    this.alertType = "success",
                    this.alertText = "设备添加完成";
                    this.alertShow = true;
                    this.clk_load = false;
                    setTimeout(() => {this.alertShow = false; this.$router.push("/dash/")}, 1500);
                    this.verifyCodeInput = '';
                } else {
                    let wd = "";
                    this.clk_load = false;
                    if (rs.endsWith("ERROR_UUID")) wd = ", 错误的 ID 格式";
                    if (rs.endsWith("LOGIN")) wd = ", 用户未登录";
                    if (rs.endsWith("ALREADY")) wd = ", ID 已被绑定";
                    if (rs.endsWith("CAPTCHA_ERROR")) wd = ", reCAPTCHA 认证未通过";
                    this.alertType = "error",
                    this.alertText = "设备添加失败" + wd;
                    this.alertShow = true;
                    setTimeout(() => {this.alertShow = false; window.location.reload();}, 2500);
                }
            }
        },
        toU() {
            this.verifyCodeInput = this.verifyCodeInput.toUpperCase();
            this.verifyCodeInput = this.verifyCodeInput.replace(/ /g, "");
            let spl = this.verifyCodeInput.split("");
            if (spl.length >= 8 && spl[8] != "-") {
                spl[7] += "-";
                this.verifyCodeInput = "";
                for(let i in spl) {
                    this.verifyCodeInput += spl[i];
                }
            }
            if (spl.length >= 13 && spl[13] != "-") {
                spl[12] += "-";
                this.verifyCodeInput = "";
                for(let i in spl) {
                    this.verifyCodeInput += spl[i];
                }
            }
            if (spl.length >= 18 && spl[18] != "-") {
                spl[17] += "-";
                this.verifyCodeInput = "";
                for(let i in spl) {
                    this.verifyCodeInput += spl[i];
                }
            }
            if (spl.length >= 23 && spl[23] != "-") {
                spl[22] += "-";
                this.verifyCodeInput = "";
                for(let i in spl) {
                    this.verifyCodeInput += spl[i];
                }
            }
        }
    },
    created: async function() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');
        // console.log(token);
        this.recaptcha = token;
    }
};

</script>

<style>
    .pr_app.login {
        text-align: center;
        margin-top: 11vh!important;
        transition: all .3s;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
    .select-v {
        margin: 0 auto;
        padding: 0;
    }
    .alert {
        position: fixed !important;
        top: 1rem;
        right: 1rem;
        /* min-width: 6rem; */
    }
</style>


<template>
    <v-app>
        <v-main>
            <div class="app pr_app login">
                <h1 class="text-h4">登录</h1>
                <div class="more">
                
                    <div class="form">
                        <v-col class="select-v"
                            cols="12"
                            >
                            <v-text-field
                                :label="username"
                                v-model="l_username"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col class="select-v"
                            cols="12"
                            >
                            <v-text-field
                                :label="password"
                                v-model="l_password"
                                outlined
                                type="password"
                            ></v-text-field>
                        </v-col>
                        
                        <v-btn id="clk_btn" @click="login" depressed 
                        :loading="clk_load" :disabled="clk_load">
                            登录
                        </v-btn>

                        <p style="opacity: 0.8;margin-top: 20px;">如果您尚未注册账号，请<router-link to="/reg/">注册</router-link>。</p>
                    </div>
                </div>
            </div>
            <v-alert
            class="alert"
                border="left"
                elevation="3"
                :type="alertType"
                v-text="alertText"
                v-show="alertShow"
            ></v-alert>
        </v-main>
    </v-app>
</template>

<script>
// import i18n from '../i18n';
import md5 from 'js-md5';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
var Lang = {
    title: "登录",
    backend: "后端地址",
    username: "用户名",
    password: "密码",
    remember: "保存登录状态",
    bkendUrl: "https://p1.endpoint.lazybones.club"
};
Lang.title = "登录";
Lang.alertText = Lang.alertType= Lang.l_password = Lang.l_username = null;
Lang.alertShow = false;
Lang.clk_load = false;
Lang.recaptcha = "";
document.title = "登录";
export default {
    name: "CKY",

    components: {},

    data: () => (Lang),

    methods: {
        async login() {
            
            let username = this.l_username;
            let password = this.l_password;
            if (!username || !password) {
                this.alertType = "error",
                this.alertText = "用户名或密码不可为空";
                this.alertShow = true;
                return false;
            }
            if (!this.recaptcha) {
                this.alertType = "error",
                this.alertText = "Google reCAPTCHA 加载失败";
                this.alertShow = true;
                return false;
            }
            this.clk_load = true;
            password = md5(password);
            let f_d = await fetch(this.bkendUrl + "/usr/login", {
                method: "POST",
                body: JSON.stringify({
                    username,
                    password,
                    recaptcha: this.recaptcha,
                }),
            }).then(res => res.text());
            if (f_d.startsWith("200")) {
                this.clk_load = false;
                this.alertType = "success",
                this.alertText = "登录成功";
                this.alertShow = true;
                localStorage.setItem("login_token", f_d.split(",")[1]);
                localStorage.setItem("username", username);
                setTimeout(() => {this.$router.push("/")}, 1500);
                return true;
            } else {
                let wd = ", 请检查用户名和密码是否正确";
                if (f_d.startsWith('403')) wd = ", reCAPTCHA 认证未通过";
                this.clk_load = false;
                this.alertType = "error",
                this.alertText = "登录失败" + wd;
                this.alertShow = true;
                setTimeout(() => {this.alertShow = false; location.reload();}, 1500);
            }
        }
    },
    created: async function() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');
        // console.log(token);
        this.recaptcha = token;
    }
};

</script>

<style>
    .pr_app.login {
        text-align: center;
        margin-top: 11vh!important;
        transition: all .3s;
    }
    .more {
        margin: 30px auto;
        animation: show_more 1.5s 1;
        line-height: 1.3;
    }
    @keyframes show_more {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    html, body {
        overflow: auto;
    }
    .select-v {
        margin: 0 auto;
        padding: 0;
    }
    .alert {
        position: fixed !important;
        top: 1rem;
        right: 1rem;
        /* min-width: 6rem; */
    }
</style>

